import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { 
  ICON_CART, 
  ICON_CLOSE,
  ICON_MENU,
  ICON_SEARCH,
} from '../../constants/icons';
import { MKT_NOTICE_KEY_HEADER } from '../../constants/marketing';
import * as tx from '../../constants/strings';
import { 
  URL_BUY_GENERAL,
  URL_BUY_PRODUCT_LINE,
  URL_HOME, 
  URL_PLAY_GENERAL, 
  URL_SELL_GENERAL, 
} from '../../constants/urls';

import { isLoggedIn } from '../../utils/auth';
import { isCurrentRoute } from '../../utils/general';

import HeaderAccount from './blocks/HeaderAccount';
import HeaderFullMenu from './blocks/HeaderFullMenu';
import HeaderSearch from './blocks/HeaderSearch';
import HeaderSubmenuBuy from './blocks/HeaderSubmenuBuy';
import HeaderSubmenuPlay from './blocks/HeaderSubmenuPlay';
import HeaderSubmenuSell from './blocks/HeaderSubmenuSell';
import HeaderUserMenu from './blocks/HeaderUserMenu';
import MiniCartSlider from '../Cart/blocks/MiniCartSlider';

import { Icon } from '../Icons/Icon';

import './style/_header.scss';

import * as cartActionCreators from '../../actions/cart';
import * as headerActionCreators from '../../actions/header';
import * as productLineActionCreators from '../../actions/product-line';
import * as storeActionCreators from '../../actions/store';
let allActionCreators = Object.assign({}, cartActionCreators, headerActionCreators, productLineActionCreators, storeActionCreators);

export class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: null,
      menuActive: null,
      menuDisplay: 'none',

      showCartIcon: true, 
      cartPulse: false, 

      noticeBackgroundColor: null,
      noticeTextColor: null,

      searchOpen: false,
      searchClosable: false,
      searchFocusSignal: 0,
    };

    this.MAX_PULSE_FREQUENCY = 1000; // CSS animation duration is 1s
    this.SLIDE_TRANSITION = 400; // Slide transition defined in CSS

    this.pulseTimeout = null;
    this.searchTimeout = null;

    this.searchDrawerRef = React.createRef();

    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    
    // Intentionally doesn't check if data already present; should try to pick up any changes
    this.props.productLineFetchMenus()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.props.storeFetchNotices()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    this.setHeaderNoticeColors();

    document.addEventListener('click', this.checkClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.cart.buylistMode) {
      if(this.props.cart.currentBuylistCart.quantity > prevProps.cart.currentBuylistCart.quantity) {
        this.pulseBubble();
      }
    } else {
      if(this.props.cart.currentCart.quantity > prevProps.cart.currentCart.quantity) {
        this.pulseBubble();
      }
    }

    if(!_.isEqual(prevProps.store.notices, this.props.store.notices)) {
      this.setHeaderNoticeColors();
    }

    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   this.handleUrlChange(this.props.location.pathname);
    // }
  }

  componentWillUnmount() {
    clearTimeout(this.pulseTimeout);
    clearTimeout(this.searchTimeout);
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {
    if(this.state.searchOpen && this.state.searchClosable) {
      let targetElement = evt.target;
      do {
        if(this.searchDrawerRef && targetElement === this.searchDrawerRef.current) {
          return null;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.closeSearch();
    }
  }

  getLogoUrl() {
    if(process.env.REACT_APP_STORE_CODE) {
      return `url('/store/${process.env.REACT_APP_STORE_CODE}/logo/logo.png')`;
    } else {
      return `url('/store/default/logo/logo.png')`;
    }
  }

  openMenu(menuVal) {
    this.setState({ 
      menuDisplay: 'block',
      menuActive: menuVal,
    }, () => {
      this.setState({ menuOpen: menuVal }, () => {
        this.props.headerSetMenuScreen(true);
        if(this.props.header.headerUserMenuOpen) {
          this.props.headerToggleUserMenu({ freezeScreen: true });
        }
      });
    });
  }

  closeMenu() {
    this.setState({
      menuDisplay: 'none',
      menuActive: null,
      menuOpen: null,
    }, () => {
      this.props.headerSetMenuScreen(false);
    });
  }

  getSelectedSubmenuTitle() {
    if(!this.state.menuOpen) {
      return tx.TX_null;
    }

    switch(this.state.menuOpen) {
      case 'buy':
        return tx.TX_BUY;
      case 'sell':
        return tx.TX_SELL;
      case 'play':
        return tx.TX_PLAY;
      default:
        return tx.TX_null;
    }
  }

  getSelectedSubmenu() {
    if(!this.state.menuOpen) {
      return null;
    }

    switch(this.state.menuOpen) {
      case 'buy':
        return <HeaderSubmenuBuy
                  hideTitle={true}
                  toggleFullMenu={false}
                  closeMenu={this.closeMenu.bind(this)} />;
      case 'sell':
        return <HeaderSubmenuSell
                  hideTitle={true}
                  toggleFullMenu={false}
                  closeMenu={this.closeMenu.bind(this)} />;
      case 'play':
        return <HeaderSubmenuPlay
                  hideTitle={true}
                  toggleFullMenu={false}
                  closeMenu={this.closeMenu.bind(this)} />;
      default:
        return null;
    }
  }

  getMenuStyle() {
    return { display: this.state.menuDisplay };
  }

  pulseBubble() {
    this.setState({
      cartPulse: true,
    }, () => {
      this.pulseTimeout = setTimeout(() => {
        this.setState({
          cartPulse: false,
        });
      }, this.MAX_PULSE_FREQUENCY);
    });
  }

  getHeaderNotice() {
    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {
        return nt;
      }
    }
    return null;
  }

  getActiveNotice() {
    if(this.props.store.noticesDismissed.includes(MKT_NOTICE_KEY_HEADER)) {
      return null;
    }
    return this.getHeaderNotice();
  }

  getNoticeStyle() {
    
    const notice = this.getActiveNotice();
    const noticeStyle = {};

    if(this.state.noticeTextColor) {
      noticeStyle['color'] = `#${this.state.noticeTextColor}`;
    } else if(notice && notice.textColor) {
      noticeStyle['color'] = `#${notice.textColor}`;
    }

    if(this.state.noticeBackgroundColor) {
      noticeStyle['backgroundColor'] = `#${this.state.noticeBackgroundColor}`;
    } else if(notice && notice.backgroundColor) {
      noticeStyle['backgroundColor'] = `#${notice.backgroundColor}`;
    }

    return noticeStyle;
  }

  dismissNotice() {
    const notice = this.getActiveNotice();

    if(notice) {
      this.props.storeNoticesDismiss(notice.position);
    }
  }

  setHeaderNoticeColors() {

    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {

        if(this.state.noticeTextColor !== nt.textColor) {
          this.setState({ noticeTextColor: nt.textColor });
        }

        if(this.state.noticeBackgroundColor !== nt.backgroundColor) {
          this.setState({ noticeBackgroundColor: nt.backgroundColor });
        }

        break;
      }
    }
  }

  openSearch() {
    this.setState({
      searchOpen: true,
      searchClosable: false,
    }, () => {
      this.searchTimeout = setTimeout(() => {
        this.setState({ searchClosable: true });
      }, this.SLIDE_TRANSITION);
    });
  }

  closeSearch() {
    this.setState({ 
      searchOpen: false,
      searchClosable: false,
    });
  }

  showShowSearchIcon() {
    // Should return false only on pages with dedicated search component on mobile, which is currently just gallery

    const nonSearchRoutes = [
      URL_BUY_GENERAL,
      URL_BUY_PRODUCT_LINE,
    ];

    for(const rt of nonSearchRoutes) {
      if(isCurrentRoute(rt)) {
        return false;
      }
    }
    return true;
  }

  render() {

    const {t} = this.props;
    const notice = this.getActiveNotice();

    return <div className={`Header ${notice ? 'notified' : ''}`}>
      <div className='headerSpacer'></div>
      <div className='headerFloatWrapper'>
        <div className='headerNotice' style={this.getNoticeStyle()}>
          {notice && notice.href ?
            <Link to={notice.linkTo} className='headerNoticeLink' style={this.getNoticeStyle()}>
              <div className='headerNoticeLiner'>
                <div className={'headerNoticeValue FlexCenter'}>{notice ? notice.copy : ''}</div>
              </div>
            </Link> :
            <div className='headerNoticeLiner'>
              <div className={'headerNoticeValue FlexCenter'}>{notice ? notice.copy : ''}</div>
            </div>
          }
          <div className='headerCloseWrapper' onClick={this.dismissNotice.bind(this)}>
            <div className='headerClose FlexCenter'>&times;</div>
          </div>
        </div>
        <div className='headerMenuAnchor'>
          <HeaderFullMenu
            notified={!!notice} />
        </div>
        <div className='headerWrapper'>
          <Link 
            to={URL_HOME}
            className='logoLink'>
            <div className='logoWrapper' style={{ backgroundImage: this.getLogoUrl() }}></div>
          </Link>
          <div 
            className={this.state.menuOpen ? `menuWrapper open ${this.state.menuOpen}Menu` : 'menuWrapper'}
            onMouseLeave={this.closeMenu.bind(this)}>
            <NavLink to={URL_BUY_GENERAL} className='primaryNavLink' activeClassName={this.state.menuOpen ? '' : 'current'}>
              <div 
                className={this.state.menuActive === 'buy' ? 'menuElementWrapper active' : 'menuElementWrapper'}
                onMouseEnter={() => this.openMenu('buy')}>
                <div
                  className='menuTli'>
                  {t(tx.TX_BUY)}
                </div>
              </div>
            </NavLink>
            <NavLink to={URL_SELL_GENERAL} className='primaryNavLink' activeClassName={this.state.menuOpen ? '' : 'current'}>
              <div 
                className={this.state.menuActive === 'sell' ? 'menuElementWrapper active' : 'menuElementWrapper'}
                onMouseEnter={() => this.openMenu('sell')}>
                <div
                  className='menuTli'>
                  {t(tx.TX_SELL)}
                </div>
              </div>
            </NavLink>
            <NavLink to={URL_PLAY_GENERAL} className='primaryNavLink' activeClassName={this.state.menuOpen ? '' : 'current'}>
              <div 
                className={this.state.menuActive === 'play' ? 'menuElementWrapper active' : 'menuElementWrapper'}
                onMouseEnter={() => this.openMenu('play')}>
                <div
                  className='menuTli'>
                  {t(tx.TX_PLAY)}
                </div>
              </div>
            </NavLink>
            <div className='menuDropdownWrapper' style={this.getMenuStyle()}>
              <div className='menuDropdownTitle'>
                {t(this.getSelectedSubmenuTitle())}
              </div>
              <div className='menuDropdownBody'>
                {this.getSelectedSubmenu()}
              </div>
              <div className='menuDropdownFooter'></div>
            </div>
          </div>
          <div ref={this.searchDrawerRef} className={`searchDrawer ${this.state.searchOpen ? 'open' : ''} ${this.state.searchClosable ? 'closable' : ''}`}>
            <div className={`searchWrapper`}>
              <HeaderSearch focusSignal={this.state.searchFocusSignal} />
            </div>
            <div className={'searchCloseWrapper'} onClick={this.closeSearch.bind(this)}>
              <Icon 
                value={ICON_CLOSE} 
                iconClass='closeIcon' />
            </div>
          </div>
          <div className='accountWrapper'>
            <HeaderAccount />
          </div>
          <div 
            className='cartWrapper'
            onClick={this.props.cartToggleMinicartSlider.bind(this)}>
            <Icon 
              value={ICON_CART} 
              iconClass='cartIconWrapper' />
            {(!this.props.cart.buylistMode && this.props.cart.currentCart.quantity) || (this.props.cart.buylistMode && this.props.cart.currentBuylistCart.quantity) ?
              <div className={this.state.cartPulse ? 'cartBubble pulse' : 'cartBubble'}>{this.props.cart.buylistMode ? this.props.cart.currentBuylistCart.quantity : this.props.cart.currentCart.quantity}</div> :
              null
            }
          </div>
          <div className='fullMenuWrapper' onClick={this.props.headerToggleUserMenu.bind(this)}>
            <Icon 
              value={ICON_MENU} 
              iconClass='fullMenuIconWrapper' />
          </div>
          {this.state.searchOpen === false && this.showShowSearchIcon() === true ?
            <div className='searchIconWrapper' onClick={this.openSearch.bind(this)}>
              <Icon 
                value={ICON_SEARCH} 
                iconClass='searchIcon' />
            </div> :
            null
          }
          {isLoggedIn() ?
            <HeaderUserMenu /> :
            null
          }          
        </div>
      </div>
      <MiniCartSlider />
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cart: state.cart, 
    header: state.header,
    store: state.store,
  };
}

export default withRouter(connect(mapStateToProps, allActionCreators)(withTranslation()(Header)));


